import React from "react";
import { Text } from "@atoms";
import { Icon, AppLink } from "@base";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

{
  /* <div id="smart-button-container">
    <div style="text-align: center"><label for="description">Notes </label><input type="text" name="descriptionInput" id="description" maxlength="127" value=""></div>
      <p id="descriptionError" style="visibility: hidden; color:red; text-align: center;">Please enter a description</p>
    <div style="text-align: center"><label for="amount">Amount </label><input name="amountInput" type="number" id="amount" value="" ><span> USD</span></div>
      <p id="priceLabelError" style="visibility: hidden; color:red; text-align: center;">Please enter a price</p>
    <div id="invoiceidDiv" style="text-align: center; display: none;"><label for="invoiceid">Invoice # </label><input name="invoiceid" maxlength="127" type="text" id="invoiceid" value="" ></div>
      <p id="invoiceidError" style="visibility: hidden; color:red; text-align: center;">Please enter an Invoice ID</p>
    <div style="text-align: center; margin-top: 0.625rem;" id="paypal-button-container"></div>
  </div>
  <script src="https://www.paypal.com/sdk/js?client-id=sb&enable-funding=venmo&currency=USD" data-sdk-integration-source="button-factory"></script>
  <script>
  function initPayPalButton() {
    var description = document.querySelector('#smart-button-container #description');
    var amount = document.querySelector('#smart-button-container #amount');
    var descriptionError = document.querySelector('#smart-button-container #descriptionError');
    var priceError = document.querySelector('#smart-button-container #priceLabelError');
    var invoiceid = document.querySelector('#smart-button-container #invoiceid');
    var invoiceidError = document.querySelector('#smart-button-container #invoiceidError');
    var invoiceidDiv = document.querySelector('#smart-button-container #invoiceidDiv');

    var elArr = [description, amount];

    if (invoiceidDiv.firstChild.innerHTML.length > 1) {
      invoiceidDiv.style.display = "block";
    }

    var purchase_units = [];
    purchase_units[0] = {};
    purchase_units[0].amount = {};

    function validate(event) {
      return event.value.length > 0;
    }

    paypal.Buttons({
      style: {
        color: 'black',
        shape: 'rect',
        label: 'pay',
        layout: 'horizontal',
        
      },

      onInit: function (data, actions) {
        actions.disable();

        if(invoiceidDiv.style.display === "block") {
          elArr.push(invoiceid);
        }

        elArr.forEach(function (item) {
          item.addEventListener('keyup', function (event) {
            var result = elArr.every(validate);
            if (result) {
              actions.enable();
            } else {
              actions.disable();
            }
          });
        });
      },

      onClick: function () {
        if (description.value.length < 1) {
          descriptionError.style.visibility = "visible";
        } else {
          descriptionError.style.visibility = "hidden";
        }

        if (amount.value.length < 1) {
          priceError.style.visibility = "visible";
        } else {
          priceError.style.visibility = "hidden";
        }

        if (invoiceid.value.length < 1 && invoiceidDiv.style.display === "block") {
          invoiceidError.style.visibility = "visible";
        } else {
          invoiceidError.style.visibility = "hidden";
        }

        purchase_units[0].description = description.value;
        purchase_units[0].amount.value = amount.value;

        if(invoiceid.value !== '') {
          purchase_units[0].invoice_id = invoiceid.value;
        }
      },

      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: purchase_units,
        });
      },

      onApprove: function (data, actions) {
        return actions.order.capture().then(function (orderData) {

          // Full available details
          console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

          // Show a success message within this page, e.g.
          const element = document.getElementById('paypal-button-container');
          element.innerHTML = '';
          element.innerHTML = '<h3>Thank you for your payment!</h3>';

          // Or go to another URL:  actions.redirect('thank_you.html');
          
        });
      },

      onError: function (err) {
        console.log(err);
      }
    }).render('#paypal-button-container');
  }
  initPayPalButton();
  </script> */
}

const Footer = () => {
  return (
    <footer className="py-12 px-3 sm:px-6 text-left text-sm">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12 w-full max-w-lg xl:max-w-xl mx-auto">
        <AppLink
          className="justify-start text-white inline-flex items-end font-bold italic hover:opacity-50 transition duration-200"
          to="https://calendly.com/lisa-laughing-phoenix"
        >
          <Icon
            name="make_appointment"
            className="h-8 sm:h-10 flex just-fiy-start"
            fitHeight
            // encode
            // eslint-disable-next-line react/no-unstable-nested-components
            placeholder={() => <Text>Make Appointment</Text>}
          />
          <Icon
            name="chevron_right_1"
            className="h-8 -mb-2 ml-2 mr-auto"
            fitHeight
          />
        </AppLink>
        <a
          className="justify-start text-white inline-flex items-end font-bold italic hover:opacity-50 transition duration-200"
          href="mailto:lisa@lisabenson.net"
        >
          <Icon
            name="contact_lisa"
            className="h-8 sm:h-10 flex just-fiy-start"
            fitHeight
            // encode
            // eslint-disable-next-line react/no-unstable-nested-components
            placeholder={() => <Text>Contact Lisa</Text>}
          />
          <Icon
            name="chevron_right_2"
            className="h-8 -mb-2 ml-2 mr-auto"
            fitHeight
          />
        </a>
        <div className="md:col-span-2 -mb-10">
          <PayPalScriptProvider
            options={{ "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID }}
          >
            <PayPalButtons style={{ layout: "horizontal", color: "black" }} />
          </PayPalScriptProvider>
        </div>
        {/* <AppLink
          className="justify-start md:col-span-2 text-white justify-center text-center inline-flex items-end font-bold italic hover:opacity-50 transition duration-200"
          to="https://calendly.com/lisa-laughing-phoenix"
        >
          <Icon
            name="payment"
            className="h-8 sm:h-10 flex just-fiy-start"
            fitHeight
            // encode
            // eslint-disable-next-line react/no-unstable-nested-components
            placeholder={() => <Text>Payment</Text>}
          />
          <Icon name="chevron_right_1" className="h-8 -mb-2 ml-2" fitHeight />
        </AppLink> */}
      </div>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-3 w-full max-w-lg xl:max-w-xl mx-auto">
        <div>
          <div className="my-3">The Laughing Phoenix, Inc.</div>
          <div className="my-3">
            <AppLink
              partiallyActive={false}
              to="/business-strategy"
              className="font-bold hover:opacity-50"
            >
              Business Strategy
            </AppLink>
          </div>
          <div className="my-3">
            <AppLink
              partiallyActive={false}
              to="/"
              className="font-bold hover:opacity-50"
            >
              Financial Life Strategy
            </AppLink>
          </div>
        </div>
        <div>
          <div className="my-3">343 Soquel Avenue, #334 </div>
          <div className="my-3">Santa Cruz, CA 95062</div>
        </div>
        <div>
          <div className="my-3">831.335.4235</div>
          <div className="my-3">
            <a className="font-bold" href="mailto:lisa@lisabenson.net">
              lisa@lisabenson.net
            </a>
          </div>
        </div>
        <div className="md:col-span-3">
          <div className="my-3">
            <AppLink to="https://designbycosmic.com">Site by Cosmic</AppLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
